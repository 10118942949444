@font-face {
  font-family: "Benedict";
  src: url("../Assets/Fonts/Benedict.otf");
}
@font-face {
  font-family: "Playfair-Display";
  src: url("../Assets/Fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Montserrat";
  src: url("../Assets/Fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Famulred";
  src: url("../Assets/Fonts/famulred/Famulred.otf");
}
.home-empty-list-message {
  text-align: center;
  color: #f0e68c;
  margin-top: 25px;
  margin-left: 4%;
  margin-right: 4%;
  opacity: 0.3;
  font-family: "Montserrat" !important;
}

body,
html {
  margin: 0 !important;
  padding: 0;
  height: 100%;
  background-color: black;
}

body.unauthenticated {
  overflow: hidden;
}

body.authenticated {
  overflow: auto;
}

.unauthenticated-container {
  background-color: black;
  background-image: url("../Assets/Images/mainBg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top: solid 3px #f0e68c;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: zoomIn 20s linear infinite alternate;
}

.authenticated-container {
  padding-top: 0px; /* Adjust to your navbar height */
  padding-bottom: 20px;
  box-sizing: border-box;
}

.nav-bar {
  margin-bottom: 3% !important;
  padding-top: 3% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: transparent;
  border: 1px solid #e6c629;
  color: #e6c629;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
}

.nav-button:hover {
  background-color: #f0e68c;
  color: black;
}

.placesText {
  text-align: center;
  color: #f0e68c;
  font-family: "Famulred" !important;
  margin-top: -2rem !important;
  font-size: 80px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hamburger-menu {
  margin-left: 10px;
}

.nav-button-active {
  background-color: #f0e68c;
  color: black;
  border-color: #e6c629;
}

.google-signin-button-container {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
}
.google-signin-button-container button {
  width: 70% !important;
}
.about-us {
  text-align: center;
  color: #f0e68c;
  font-family: "Montserrat";
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
  border-radius: 10px;
  max-width: 600px;
}

.about-us-content {
  font-size: 16px !important;
  line-height: 1.1 !important;
  font-weight: 300 !important;
  font-family: "Montserrat";
}

.main-content {
  padding-bottom: 70px;
}

.custom-dialog {
  background-color: transparent;
}

.custom-dialog .MuiDialog-paper {
  min-width: 80%;
}

.custom-dialog-paper {
  width: 80% !important;
  max-width: none !important;
}

.home-filter-buttons-container {
  display: flex;
  justify-content: center;
  gap: 2%; /* Space between buttons */
  margin-bottom: 20px;
  color: #f0e68c !important;
  border-top: 1px solid #d4af37 !important;
  width: 95%;
  margin-left: 2.5%;
}

.home-filter-button {
  flex: 1;
  min-width: 0;
  text-align: center;
  color: #f0e68c !important;
  border-color: #d4af37 !important;
  border-top: none !important;
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
}
.home-filter-button.MuiButton-contained {
  background-color: #d4af37 !important;
  color: black !important;
}

.add-card-button {
  position: fixed;
  width: 93.3% !important;
  bottom: 10px !important;
  margin-top: 7% !important;
  margin-left: 4% !important;
  margin-bottom: -5% !important;
  color: #f0e68c !important;
  border: 2px solid #f0e68c !important;
  height: 100px;
  font-size: 20px;
  background-size: cover;
  background-position: center;
  overflow: hidden; /* Ensure the pseudo-element is contained within the button */
  z-index: 0;
}

.add-card-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: -1; /* Ensure the overlay is behind the text */
}

.add-card-button > * {
  position: relative;
  z-index: 1; /* Ensure button content is above the overlay */
}

@media (min-width: 601px) {
  .unauthenticated-container {
    padding-bottom: 300px !important;
  }
}

/* Mobile View - Fixed Navbar at the Bottom */
@media screen and (max-width: 820px) {
  .nav-bar {
    position: fixed;
    bottom: 0;
  }

  .unauthenticated-container {
    margin-bottom: -50%;
  }

  .add-card-button {
    position: fixed;
    width: 93.3% !important;
    bottom: 10px !important;
    margin-top: 7% !important;
    margin-left: 4% !important;
    margin-bottom: -5% !important;
    color: #f0e68c !important;
    border: 2px solid #f0e68c !important;
    height: 100px;
    font-size: 20px;
    background-size: cover;
    background-position: center;
    background-blend-mode: darken;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  .placesText {
    margin-top: 1rem !important;
  }

  .hamburger-menu {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
