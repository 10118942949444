.desktop-landing-page {
  display: none; /* Hide by default */
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: #f0e68c;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  text-align: center;
}

/* Show only on larger screens (e.g., desktops) */
@media (min-width: 769px) {
  .desktop-landing-page {
    display: flex;
  }
}
