.restaurant-card {
  position: relative;
  max-width: 400px; /* Increased the width */
  height: 300px;
  margin-bottom: 20px;
  overflow: hidden;
  border: solid 3px #f0e68c;
  border-radius: 8px; /* Add border-radius for a rounded effect */
}

.card-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 2;
}

.card-text {
  color: #f0f0f0;
  display: flex; /* Ensure the content is flex for proper layout */
  justify-content: space-between; /* Space the elements evenly */
  align-items: center; /* Align items to the center */
}

.restaurant-card .MuiRating-iconEmpty .MuiSvgIcon-root {
  color: white;
}

.card-buttons {
  display: flex;
  align-items: center;
  gap: 8px; /* Add space between icons */
  opacity: 0.8;
  margin-top: -3px !important;
  margin-left: -10px !important;
}
