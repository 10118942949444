.mobile-experience-card-container {
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: solid 3px #f0e68c;
  perspective: 1000px; /* Add perspective for 3D flip */
}

.compact-view {
  position: relative;
  width: 100%;
  height: 100px; /* Adjust the height as needed */
  overflow: hidden;
}

.card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  color: rgb(203, 199, 199) !important;
}

.crowd-level-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 8px;
}
.card-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.card-content {
  position: absolute;
  bottom: 0 !important;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.text-content {
  display: flex;
  flex-direction: column;
}

.name {
  margin: 0;
  color: #fff;
  font-weight: bold;
}

.location {
  margin: 0;
  color: #ccc;
  font-size: 0.8em;
}

.delete-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #f0e68c;
  cursor: pointer;
  z-index: 2;
}

.impressions {
  display: flex;
  justify-content: center;
  line-height: 0.5px;
}

.card-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.edit-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.card-details {
  width: 100%;
  height: 100%;
}

.expanded-card,
.expanded-card-back {
  position: relative;
  width: 100%;
  height: 300px; /* Ensure the card takes full height */
  overflow: hidden;
  padding-bottom: 0% !important;
}

.expanded-card .card-content {
  justify-content: flex-end; /* Move content to the bottom */
  padding-bottom: 0% !important;
}

.card-back-mobile {
  background-color: black !important;
}

.flip-card {
  width: 100%;
  height: 300px; /* Ensure the card takes full height */
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
  background-color: black; /* Set background to black */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; /* Ensure text is visible */
}

.edit-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #e6c629; /* Edit button color */
}

/* Progress bar */
.MuiLinearProgress-bar {
  background-color: #f0e68c !important;
}

.MuiLinearProgress-root {
  background-color: transparent !important;
  border: solid 1px #e6c629 !important;
  border-radius: 5px;
}
.mobile-experience-card-container .card-background {
  position: relative;
  height: 200px;
  overflow: hidden;
  background-color: #f0f0f0; /* Placeholder background color */
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat;
}

.mobile-experience-card-container .expanded-background {
  height: 300px; /* Adjust the height for the expanded view */
}

.mobile-experience-card-container .card-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.mobile-experience-card-container .image-placeholder {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
}
