/* Footer.css */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: white;
  padding: 0.5rem 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-top: solid 1px #f0e68c;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-button {
  text-decoration: none;
}

.footer-button .MuiIconButton-root {
  color: #f0e68c;
}

.footer-button .MuiTypography-caption {
  color: #f0e68c;
  display: block;
}
@media (min-width: 720px) {
  .footer {
    margin-top: 30%; /* Adjust the value as needed */
  }
}
