@font-face {
  font-family: "Montserrat";
  src: url("../Assets/Fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}

.feed-title {
  color: white !important;
  font-family: "Montserrat" !important;
}

/* FeedPage.css */
.feed-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  gap: 20px; /* space between cards */
}

.category-card {
  max-width: 200px;
  height: 200px; /* Make it square */
  margin: auto;
  background-size: cover; /* Ensure the background image covers the entire card */
  position: relative; /* Needed for positioning the content */
  display: flex;
  align-items: flex-end; /* Align content to the bottom */
}

.category-card-content {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  color: white;
  padding: 10px;
}

.categories-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px; /* Adjust as needed */
}

.category-card-content {
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for text readability */
  padding: 10px;
}

.category-card:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.selected-category {
  text-align: center; /* Center category name */
  color: #f0e68c; /* Set color to yellow */
  font-family: "Montserrat" !important;
  font-weight: 400;
}

.search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  position: relative;
  color: white;
  padding-left: 0% !important;
  margin-left: 1% !important;
  margin-bottom: 10%;
  width: 88%;
}

.search-bar-container .MuiIconButton-root {
  margin-left: 1px;
  position: absolute;
  left: 0;
}

.search-bar-container .MuiTextField-root {
  flex-grow: 1;
  margin-left: 40px;
}

.hamburger-menu {
  position: absolute;
  right: 0;
}

.location-access-warning {
  color: red; /* Red color for the warning message */
  font-size: 1.2rem; /* Larger font size */
  text-align: center; /* Center align the text */
  margin-top: 20px; /* Add some space at the top */
  font-weight: bold; /* Make the text bold */
}

.search-bar-container .MuiTextField-root {
  width: 70%; /* Adjust width as needed */
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #f0e68c !important;
}

.restaurant-card {
  flex-basis: calc(33.333% - 20px); /* Three cards per row with gap */
  box-sizing: border-box;
  height: 300px; /* fixed height for cards */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute space inside card */
}

/* Add overflow handling for content inside the card */
.restaurant-card-content {
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis to overflow text */
}

.main-category-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #181818;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  transition: transform 0.3s ease;
  cursor: pointer;
  height: 150px;
  position: relative;
  overflow: hidden;
}

.main-category-card-content {
  color: #b7b9bb;
  font-weight: 100 !important;

  z-index: 2; /* Ensure content is above the image */
}

.category-preview {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0.3;
}

.main-category-card:hover {
  transform: translateY(-5px);
}

/* Menu item styling */
.menu-container {
  position: absolute;
  right: 0;
}

.MuiMenuItem-root {
  opacity: 0.8;
  cursor: pointer;
  padding: 10px 20px;
}

.MuiMenuItem-root:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Add hover effect */
}

/* Drawer styling */
.drawer-container {
  width: 250px;
  padding: 20px;
  background-color: black;
  color: white;
}

.feed-filter-buttons-container {
  display: flex;
  justify-content: center;
  gap: 2%; /* Space between buttons */
  margin-bottom: 20px; /* Space below the buttons */
  border-top: solid 1px #d4af37;
  color: black !important;
  width: 95% !important;
  margin-left: 2.1% !important;
}

.feed-filter-button {
  flex: 1;
  min-width: 0;
  text-align: center;
  border: 1px solid #d4af37 !important;
  border-top: none !important;
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  padding: 10px;
  background-color: black !important;
  color: #f0e68c !important;
  height: 40px;
}

.active-filter-button {
  background-color: #e6c629 !important;
  color: black !important;
  height: 37px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .restaurant-card {
    flex-basis: 100%; /* Two cards per row on tablets */
  }
  .category-container {
    padding-bottom: 20% !important;
  }
  .category-card {
    width: calc(50% - 20px); /* Adjust width for smaller screens */
  }
  .categories-container {
    padding-bottom: 20%;
  }
  .feed-container {
    padding-bottom: 25%;
  }
}

@media (max-width: 480px) {
  .restaurant-card {
    flex-basis: 100%; /* Full width on mobile */
  }
}
