.navbar {
  display: flex;
  justify-content: space-between; /* Aligns items to each side and center */
  align-items: center;
  background-color: black;
  border-bottom: solid 1px #f0e68c;
  padding: 0.5rem 1rem; /* Adjust padding as needed */
}

.navbar-section {
  display: flex;
  align-items: center;
}

.navbar-section.left {
  flex: 1; /* Takes up the space needed */
  justify-content: flex-start;
}

.navbar-section.center {
  flex: 2; /* Allows more space for the center items */
  justify-content: center;
}

.navbar-section.right {
  flex: 1; /* Takes up the space needed */
  justify-content: flex-end;
}
.navbar a {
  color: black;
  text-decoration: none;
  padding: 8px 16px;
  display: block;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.left-items,
.right-items {
  display: flex;
  align-items: center;
}

.profile-icon {
  position: relative;
  cursor: pointer;
  color: #f0e68c;
}
.dropdown-menu {
  position: absolute;
  right: -10px;
  top: calc(100% + 0.87rem);
  background-color: #e6c629;
  list-style-type: none;
  padding: 0.5rem 0;
  margin: 0;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  min-width: 200px !important;
  width: 220px;
  z-index: 1000; /* Ensure this is higher than any other elements on the page */
  display: none;
}

.user-name {
  display: inline-block;
  padding: 0px 15px 5px 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: solid;
  border-left: hidden !important;
  border-color: #f0e68c;
  margin-right: 0px !important;
  position: relative;
  top: -10px;
}

.profile-icon .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #0c2a45;
  color: black;
}
.user-name {
  margin-left: 10px;
  color: #f0e68c;
  font-size: 1rem;
}
.container {
  overflow: visible; /* Makes sure the shadow isn't cut off */
}
.search-bar {
  width: 300px;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 20px;
  border: none !important;
}

.logo-container {
  padding: -5px;
}

.logo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: #0c2a45;
  border-color: black;
}
.logo:hover {
  background-color: none;
}
.logo-cart-container {
  position: relative;
  display: inline-block;
}
.hidden {
  display: none;
}
.cart-quantity {
  position: absolute;
  top: 35%;
  left: 57%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  color: #f0e68c;
  font-weight: bolder;
  border-radius: 50%;
  padding: 0.2rem 0.5rem;
  font-size: 0.6rem;
  z-index: 1;
  font-family: inherit;
}
.nav-link {
  margin: 0 10px; /* Adjust spacing as needed */
}

.nav-link Button {
  color: #f0e68c;
  padding: 10px 20px; /* Padding inside the button */
  text-transform: uppercase; /* Making the text uppercase for styling */
  font-weight: bold;
  border-radius: 4px; /* Rounded corners */
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);  */
  border: solid 1px #f0e68c;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.nav-link Button:hover {
  background-color: #f0e68c; /* Darker shade on hover */
  border-top: hidden;
  color: black;
}

/* Restyle dropdown menu position if needed */
.dropdown-menu {
  right: 0; /* Align dropdown to the right edge of the profile icon */
}

@media only screen and (min-width: 415px) {
  .search-bar {
    width: 350px;
  }
  .user-name {
    display: inline-block;
    position: relative;
    top: -10px;
    margin-right: 0px !important;
  }
  .profile-icon {
    margin-top: 13px;
    margin-right: 5px;
  }
}

.desktop-icon {
  display: inline;
}

.mobile-initials {
  display: none;
}

@media only screen and (max-width: 768px) {
  .logo {
    display: block;
  }

  .logo-cart-container {
    margin-left: -30px !important;
  }
  .desktop-icon {
    display: none;
  }
  .nav-link Button {
    padding: 8px 10px; /* further reduce padding on smaller screens */
    font-size: 0.8rem; /* reduce font size on smaller screens */
  }
  .mobile-initials {
    margin-top: 0px !important;
    margin-left: -10px;
    display: inline;
    border: 1px solid #f0e68c;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: #f0e68c;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: #0c2a45;
    font-weight: 400;
  }
  .user-name {
    display: none;
  }

  .profile-icon {
    margin-top: 0px !important;
    margin-right: 5px;
  }
  .dropdown-menu {
    position: absolute;
    right: -15px;
    top: calc(100% + 2.2rem);
  }

  .navbar-section.left,
  .navbar-section.right {
    flex: 0; /* Don't grow or shrink */
  }

  .navbar-section.center {
    flex: 1; /* Allow center section to fill the space */
    flex-direction: row; /* Align buttons horizontally */
    justify-content: center; /* Center buttons */
  }

  .nav-link {
    margin: 0 5px; /* Reduce margin between buttons */
  }

  .nav-link Button {
    padding: 8px 15px; /* Smaller padding on mobile */
    font-size: 0.8em; /* Smaller font size on mobile */
  }
  .navbar-section.center {
    margin-left: -9%;
  }
  .logo-cart-container {
    margin-left: 0; /* adjust as needed */
  }

  /* Reduce the padding and font size inside the buttons */
  .nav-link Button {
    padding: 2px 8px; /* Smaller padding on mobile */
    font-size: 0.7rem; /* Smaller font size on mobile */
  }

  /* Adjust the user icon and name for mobile */
  .profile-icon {
    margin-top: 0;
    margin-right: 0;
    margin-left: 2px !important;
  }
}

@media (max-width: 640px) {
  .navbar {
    background-color: black;
  }
}
@media only screen and (max-width: 455px) {
  .profile-icon {
    margin-top: 20px;
    margin-right: 10px; /* Adjusted margin for better spacing */
  }
  .search-bar {
    max-width: 230px;
    margin-left: -17px; /* Adjusted margin for better spacing */
  }
}
