.add-experience-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: black; /* Black background for the form */
  color: white; /* White text color for the form */
}

.camera-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MuiFormControl-root {
  margin: 10px 0;
}

.MuiInputLabel-root,
.MuiInput-input::placeholder {
  color: white !important;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #e6c629 !important;
}

.MuiButton-root {
  color: white;
  border: 1px solid white;
  margin-top: 20px;
}

.MuiButton-contained {
  background-color: #e6c629 !important; /* Button background color */
}

.MuiMenu-paper {
  background-color: black;
  color: white;
}

.MuiMenuItem-root {
  color: white !important;
}

.add-experience-container {
  width: 92%;
}

.expanded-card {
  border: #e6c629 2px solid;
}

.card-content {
  position: absolute;
  bottom: 0 !important;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.MuiButton-contained {
  color: white !important;
  margin-bottom: 5px !important;
  width: 60% !important;
}
