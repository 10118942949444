/* PrivacyPolicy.css */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align to the top */
  z-index: 9999;
  overflow-y: auto; /* Allow scrolling */
  padding-top: 60px; /* Space from the top, adjust this to the height of your navbar */
}

.popup {
  background: #e6c629;
  width: 90%;
  max-width: 600px;
  margin-top: 0px; /* Distance from the navbar */
  margin-bottom: 10%;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative; /* For absolute positioning of the close button */
  z-index: 10000;
}

.close-cart {
  margin-top: 3%;
  margin-right: 3%;
  background-color: #e6c629;
  color: #0c2a45;
  border: none;
  border-radius: 10%;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
