@font-face {
  font-family: "Benedict";
  src: url("../Assets/Fonts/Benedict.otf");
}
@font-face {
  font-family: "Playfair-Display";
  src: url("../Assets/Fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Montserrat";
  src: url("../Assets/Fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Famulred";
  src: url("../Assets/Fonts/famulred/Famulred.otf");
}
.navbar h2 {
  font-family: "Famulred" !important;
  font-size: 80px;
  margin-top: 0px;
  margin-bottom: 5px;
}

.ListContainer {
  margin-bottom: 15%;
  padding-left: 15px;
  padding-right: 15px;
}

.drawer-container {
  padding: 20px;
  background-color: transparent; /* Ensure the background has the correct opacity */
  width: 90%;
}

.drawer-title {
  color: #f0e68c;
  margin-bottom: 20px;
}

.drawer-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.drawer-checkbox {
  margin-bottom: 8px;
}

.drawer-title {
  position: sticky;
  top: 0;
  background-color: black; /* Match the drawer background */
  color: #f6f1c7;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #f0e68c;
  margin-top: 0px;
  z-index: 1;
}

.drawer-checkbox {
  display: block; /* Ensure one filter per line */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000; /* Match your existing design */
  padding: 10px;
}

h2 {
  color: #f0e68c;
  text-align: center;
  font-weight: 500;
}

.empty-list-message {
  text-align: center;
  color: #f0e68c;
  font-size: 1em;
  margin-top: 20px;
  opacity: 0.6;
  font-family: "Montserrat" !important;
}

.empty-list-message p {
  margin: 10px 0;
}

.empty-list-message a {
  color: #f0e68c;
  text-decoration: underline;
}

.add-circle-icon {
  vertical-align: middle;
  margin-left: 5px;
  color: #f0e68c;
}

.feed-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.restaurant-card-wrapper {
  margin: 0px;
  min-width: 115%;
}

.filter-buttons-container {
  display: flex;
  justify-content: center;
  gap: 2%;
  margin-bottom: 20px;
  color: #f0e68c !important;
  border-top: 1px solid #d4af37 !important;
  width: 100%;
  /* margin-left: 2.5%; */
}

.filter-button {
  flex: 1;
  min-width: 0;
  text-align: center;
  color: #f0e68c !important;
  border-top: none !important;
  border-color: #d4af37 !important;
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
}
.filter-button.MuiButton-contained {
  color: black !important;
  background-color: #d4af37 !important; /* Darker yellow when selected */
}

@media (max-width: 768px) {
  .empty-list-message {
    font-size: 1em;
  }

  .restaurant-card-wrapper {
    width: 150% !important; /* Ensure the cards take full width on small screens */
  }

  .filter-buttons-container {
    flex-wrap: nowrap;
  }

  .filter-button {
    flex: 1 1 0; /* Ensure buttons are equal size and fit in one line */
  }
}
